<template>
  <div id="app">
    <Nav />
    <div class="mt-50">
      <img style="width:100%;" :src="require(`@/assets/banner/banner-02${$i18n.locale == 'zh'?'z':'e'}.png`)" alt="" />
      <div class="brand-top">
        <div class="brand-title">{{ detailInfo.description }}</div>
        <div class="brand-time mt-5">
          {{ __commonJs["happenTimeFun"](detailInfo.inputtime * 1000, "ymd") }}
        </div>
      </div>
      <div class="brand-bot" v-html="contents">
        <!-- <div class="viewMain"> -->
          <!-- <div v-html="contents"></div> -->
        <!-- </div> -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      id: "",
      detailInfo: {},
    };
  },
  computed: {
    contents() {
      let content = this.detailInfo.content || "";
      let newStr = content.replace(
        /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
        function (match, capture) {
          if (!/http|https/.test(capture)) {
            return `<img src="https://www.suzhousco.com${capture}" alt="" />`;
          } else {
            return '<img src="' + capture + '" alt="" />';
          }
        }
      );
      return newStr;
    },
  },
  created() {
    this.id = this.$route.query.type || 0;

    this.getNewsDetail();
  },
  methods: {
    // 获取演出列表
    getNewsDetail() {
      this.$ajax
        .get("/cms/api/pinpaidetail", {
          id: this.id,
        })
        .then((res) => {
          if (res.code == "200") {
            this.detailInfo = res;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-top {
  padding: 20px 12px 12px;
  .brand-title {
    font-size: 18px;
    line-height: 27px;
    color: #333;
    text-align: center;
  }
  .brand-time {
    color: #666666;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #D8D8D8;
  }
}
.brand-bot{
    padding:0 12px 20px;
}
</style>
<style lang="scss">
.brand-bot{
  img{
     max-width: 100%;
  }
}
</style>

